.upper-case {
    text-transform: uppercase;
}

.capitalize {
    text-transform: capitalize;
}

.border-none {
    border-top: none !important;
    border-bottom: none !important;
    border-left: none !important;
    border-right: none !important;
}
.ellipsis{
    max-width:250px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.align-self-stretch {
    align-self: stretch;
}

.pointer {
    cursor: pointer !important;
}

.fast {
    animation-delay: 0ms;
    animation-duration: 0.3s;
}

.text-center {
    text-align: center;
}

.full-height {
    height: 100%;
}

.marginTopNone {
    margin-top: 0 !important;
}


.loadContainer {
    position: relative;
}

.loadElement {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.loadContainer {
    position: relative;
    width: 90vw;
    height: 90vh;
    border: 3px solid steelblue;
    margin: auto;
}

.loadElement {
    width: 50vw;
    height: 50vh;
}

.contenteditable[placeholder]:empty::before {
    content: attr(placeholder);
    color: #777;
}

.search-container {
    display: flex;
    flex-direction: row;
    justify-content: stretch;
    width:100%;
    height:100%;
    position:absolute;
    overflow: hidden;
    top:0;
}
.border-right{
    border-right:1px solid #ccc;
    border-radius: 0px !important;
}
.left-col {
    display: flex;
    flex: 3;
    align-items: stretch;
    background-color: #fff;
    overflow:auto;
    margin-top: 50px;
}

.right-col {
    flex: 5;
    display: flex;
    margin-top:50px;
    align-items: stretch;
    height: 100%;
}
.table-bordered{
    border:1px solid #ccc;
}

.table-no-border td{
    border-bottom: none !important;
}